import { Environment } from './types';

export const environment: Environment = {
  production: true,
  customerFacing: true,
  showErrorTraces: false,
  authCallbackUrl: 'https://regeye.fcgapps.com/auth/callback',
  oauthScopes: 'openid+email+https://api.regeye.ai/init',
  selectCognitoApiBaseUrl:
    'https://ihw9acinve.execute-api.eu-north-1.amazonaws.com',
  hostedLoginBaseUrl: 'https://auth.fcgapps.com',
  apiBaseUrl: 'https://kqgd9yl3t6.execute-api.eu-north-1.amazonaws.com/Prod',
  notificationApiBaseUrl: 'https://yf6tlvkh55.execute-api.eu-north-1.amazonaws.com',
  documentationApiUrl:
    'https://sywdc8y459.execute-api.eu-north-1.amazonaws.com/documentation/regeye',
};
